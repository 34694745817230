import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e293b",
      medium: "#242f41",
      light: "#a6c2f4",
    },
    secondary: {
      main: "#fff",
    },
    text: {
      main: "#000000",
    },
    common: {
      disabledGrey: "#bdbdbd",
    },
    error: {
      main: "#FF6161",
    },
    success: {
      main: "#96A608",
    },
    slider: {
      main: "#242f41",
    },
  },
  typography: {
    h1: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "3.5rem",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "2rem",
      fontWeight: 400,
    },
    h3: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "1.25rem",
      fontWeight: 400,
    },
    body1: {
      fontFamily: "IBM Plex Sans",
      fontSize: "1rem",
      letterSpacing: "0px",
      fontWeight: 400,
    },
  },
  overrides: {},
});

export default theme;
