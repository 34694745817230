import { 
    ADD_TO_NOTICES, 
    REMOVE_FROM_NOTICES, 
    CLEAR_NOTICES, 
 } from "../actions/action-types"

export default function noticesReducer(state, action) {
    let newNotices = [...state]

    // check if adding an item that's already in the notices
    let existingIndex
    if (action.payload) {
        existingIndex = state.findIndex(notice => notice === action.payload.notice)
    }

    // update notices state (context) and local storage function
    const saveData = notices => {
        localStorage.setItem("notices", JSON.stringify(notices))
    }

    switch (action.type) {

        case ADD_TO_NOTICES:
            
            // add new item
            newNotices.push(action.payload)

            // save notices
            saveData(newNotices)
            return newNotices
            
        case REMOVE_FROM_NOTICES:
            // remove item from notices 
            console.log("action.payload:", action.payload)
             newNotices = newNotices.filter(notice => notice.notice.data.id !== action.payload.notice.data.id)

            saveData(newNotices)
            return newNotices
                
        case CLEAR_NOTICES:
            // remove notices from local storage
            localStorage.removeItem("notices")

            return[]
        
        default:
            return state
    }
}