import React, { useReducer, createContext } from "react"
import noticesReducer from "../reducers/notices-reducer"

export const NoticesContext = createContext()

const NoticesProvider = NoticesContext.Provider
export function NoticesWrapper({ children }) {

    // bring notices from local storage ( null if server action )
    const storedNotices = typeof window !== "undefined" 
        ? JSON.parse(localStorage.getItem("notices"))
        : null
    const [notices, dispatchNotices] = useReducer(noticesReducer, storedNotices || [])

    return <NoticesProvider value={{ notices, dispatchNotices }}>{children}</NoticesProvider>
}