import React, { useReducer, createContext } from "react"

import { Snackbar, Alert } from "@mui/material"
import { setSnackbar } from "../actions"
import feedbackReducer from "../reducers/feedback-reducer"

export const FeedbackContext = createContext()

const FeedbackProvider = FeedbackContext.Provider

export function FeedbackWrapper({ children }) {
    const [feedback, dispatchFeedback] = useReducer(feedbackReducer, {
        open: false,
        severity: "success",
        message: "",
    })

    return (
        <FeedbackProvider value={{ feedback, dispatchFeedback }}>
            {children}
            <Snackbar
                open={feedback.open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={2000}
                onClose={() => dispatchFeedback(setSnackbar({ open: false }))}
            >
                <Alert 
                    variant="filled"
                    sx={{ minWidth: '400px' }}
                    severity={feedback.severity} 
                    onClose={() => {}}
                >
                    {feedback.message}
                </Alert>
            </Snackbar>

        </FeedbackProvider>
    )
}