import React from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ThemeProvider } from "@mui/material/styles";
import { ApolloWrapper } from "../../apollo/ApolloWrapper";

import { UserWrapper, NoticesWrapper, FeedbackWrapper } from "../../contexts";

import theme from "./theme";

const element = ({ element }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <ApolloWrapper>
          <UserWrapper>
            <NoticesWrapper>
              <FeedbackWrapper>{element}</FeedbackWrapper>
            </NoticesWrapper>
          </UserWrapper>
        </ApolloWrapper>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default element;
