import React, { useEffect, useState, useReducer, createContext } from "react";
import axios from "axios";
import userReducer from "../reducers/user-reducer";
import { setUser } from "../actions";

const defaultContext = {
  user: "",
};

export const UserContext = createContext(defaultContext);
const UserProvider = UserContext.Provider;

export function UserWrapper({ children }) {
  const guestUserID = process.env.GATSBY_GUEST_ID ?? 1;

  const [defaultUser] = useState({
    id: Number(guestUserID),
    username: "Guest",
    email: "guest@chikitea.com",
    country: "n/a",
  });

  const storedUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null;

  let [user, dispatchUser] = useReducer(userReducer, storedUser || defaultUser);

  //console.log("user:", user)

  // updates user profile on page refresh (delay 3 seconds)
  useEffect(() => {
    if (storedUser) {
      // DISPATCH STORED USER
      setTimeout(() => {
        //console.log("dispatching stored user...")
        //console.log("storedUser:", storedUser)

        axios
          .get(process.env.GATSBY_STRAPI_API_URL + "/api/users/me", {
            headers: {
              Authorization: `Bearer ${storedUser.jwt}`,
            },
          })
          .then((response) => {
            //console.log("response.data:", response.data)

            let updatedUser;

            updatedUser = {
              ...response.data,
              jwt: storedUser.jwt,
              onboarded: true,
            };

            dispatchUser(setUser(updatedUser));
          })
          .catch((error) => {
            console.error(error);
            dispatchUser(setUser(defaultUser));
          });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log("defaultUser:", defaultUser)
  //console.log("calcUser:", calcUser)

  return (
    <UserProvider value={{ user, dispatchUser, defaultUser }}>
      {children}
    </UserProvider>
  );
}
